import React from "react"
import MainFooter from "../../../footer/main_footer"
import MainHeader from "../../../main_header/main_header"
import data_quest from "../banner/static/data_quest.webp"
import * as classes from "./data_quest_training.module.scss"
import loadable from "@loadable/component"
const Banner = loadable(() => import("../banner/banner"), {
  fallback: <div></div>,
})

export default function DataQuest() {
  return (
    <React.Fragment>
      <MainHeader />
      <Banner title={"Data Quest Training"} backgroundImage={data_quest} />
      <div className={classes.text_div}>
        <h2>Data Quest Training</h2>
        <p>
          Most jobs in data analytics involve gathering and cleaning data to
          uncover trends and business insights. The day-to-day data management
          jobs vary depending on the industry or company or the type of data
          analytics you consider to be your specialty. You will be working with
          real life data sets and be challenged to solve problems through the
          lens of a data scientist. Interacting with content through coding will
          let you get a perfect hands on approach to learning all about data
          quest and analysis. Master Trainers offers training for Data Science,
          Data/Business Analyst as well as Data Engineer programs.
        </p>
        <p>Data quest training is for:</p>
        <div className={classes.list_div}>
          <ul className={classes.list}>
            <li>Data analysts </li>
            <li>Computer graduates </li>
            <li>Data practitioners</li>
            <li>Data scientists </li>
          </ul>
          <div className={classes.buttons_div}>
            <a
              href="https://mastrainers.com/downloads/python_track.pdf"
              className={classes.button}
              target="blank"
            >
              Python Training
            </a>
            <a
              href="https://mastrainers.com/downloads/data_analyst_training_program.pdf"
              className={classes.button}
              target="blank"
            >
              Data Analyst Training
            </a>
          </div>
        </div>
      </div>
      <MainFooter />
    </React.Fragment>
  )
}
